import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../Assets/swap/close.svg";
import lottie from "lottie-web";
import { Button } from "antd";
import "../Admin/SidebarAdmin/AdminContent/AdminContent.css";
import { useWeb3React } from "@web3-react/core";
import { exchangeContract, userBalance } from "../../util/contractIntractions";
import {AiOutlineWarning} from "react-icons/ai";
import { networks } from "../../util/networks";
import { priceConversion } from "../../util";
const TransferOwnerShipModal = ({
  type1,
  type2,
  type4,
  type5,
  title,
  handleCancel,
  ownerError,
  setOwnerError,
  modalType = '',
  adminAddress
}) => {
  const { account, library, chainId, deactivate } = useWeb3React();

  const Successcontainer = useRef(null);
  const Waitingcontainer = useRef(null);
  const [address, setAddress] = useState(null);
  const [prevOwner, setPrevOwner] = useState(null);

  useEffect(() => {
    const fetchDetails = async() => {
      const exchange = await exchangeContract(library);
      const owner = await exchange.methods.owner().call();
      console.log(account , owner)
      
      setPrevOwner(owner)
    }
    if(account){
      fetchDetails();
    }
  },[account ,prevOwner])
  const [networkData, setNetworkData] = useState(null);

  const [userBalanceWallet, setUserBalanceWallet] = useState(0);
  const [userBalanceWalletUsd, setUserBalanceWalletUsd] = useState(0);

  useEffect(() => {
    lottie.loadAnimation({
      container: Successcontainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: require("../../Lottie/txnsuccess.json"),
    });
      lottie.loadAnimation({
        container: Waitingcontainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: require("../../Lottie/waiting.json"),
      });
  }, [type4,type5]);


  const handlerAddressChange = (e)  => {
    setAddress(e.target.value)
  }
const transferHandler = async() => {
  if (account) {
    try {
      const exchange = await exchangeContract(library);
      const owner = await exchange.methods.owner().call();
      // setPrevOwner(owner);
      if (owner === account) {
        if(modalType !== 'settings'){
          await exchange.methods
          .transferOwnership(address)
          .send({ from: account })
          .on("transactionHash", (hash) => {
            setAddress('')
            // setIsOpen(false)
            // setIsPaymentSuccess(true);
            console.log(hash);

          });
        } else {
          await exchange.methods
          .handleSetLtcAdmin(address)
          .send({ from: account })
          .on("transactionHash", (hash) => {
            setAddress('')
            // setIsOpen(false)
            // setIsPaymentSuccess(true);
            console.log(hash);

          });
        }
      } else {
      // setIsLiquidity(true);
      setAddress('')
      setOwnerError(true);
        // alert("You are not owner");
      }
    } catch (error) {
      setOwnerError(true);

      setAddress('')
      if(error.code === 4001){
        // setIsModalOpen(false)
      }
      // setIsLiquidity(true);
      console.log(error, "error");
    }
  }
};

useEffect(() => {
  const fetchDataBalanceAndNetworkData = async () => {
    console.log(networks);
    setNetworkData(networks[chainId]);
    setUserBalanceWallet(await userBalance(prevOwner, library));
    setUserBalanceWalletUsd(
      (await priceConversion(networks[chainId].token,"usd")));
  };

  if (prevOwner) {
    fetchDataBalanceAndNetworkData();
  }
}, [chainId, prevOwner, library]);
debugger
console.log(networkData);
console.log(address)
  return (
    <div>
      <div className="close-div">
        <img onClick={handleCancel} src={CloseIcon} />
      </div>

      {modalType === 'settings' && type1 && (
        <div className="transferownership-wrapper">
          <p className="balance-header">Owner Balance</p>
            <div className="lct-main-wrapper-div" style={{ width: "70%" }}>
              <div className="logo-div-wrapper-icon">
                <img src={networkData?.icon} />
              </div>
              <div>
                <p className="lct-val-text">
                  <span style={{ color: "#F3BA2F" }}>
                    {networkData?.currency}
                  </span>{" "}
                  {userBalanceWallet}
                </p>
                <p className="usd-lct-text">
                  USD Value:${(userBalanceWallet * userBalanceWalletUsd).toFixed(4) }
                </p>
              </div>
            </div>
            <div className="divider-modal"></div>
          <p id="transfer-title">{title}</p>
          <p className="transfer-owner-title">Recent Owner’s Address</p>
          <p className="wallet-addr-div">
            {prevOwner}
          </p>
          <p className="transfer-owner-title">
            Enter New Admin wallet address
          </p>
          <div className="ownership-input">
            <input style={{border: type2 && '1px solid red'}}  placeholder={`please enter new admin address`}  value={address}  onChange={handlerAddressChange} />
          </div>
          {prevOwner !== account && <div className="owner-error-div"> <AiOutlineWarning/> <p>You are not an owner</p></div>}
          <div className="ownership-button">
            <Button disabled={address === null || address=== '' || prevOwner !== account ? true:false} type="primary" onClick={transferHandler}>
              {title}
            </Button>
          </div>
        </div>
      )}
      
      {modalType !== 'settings' && type1 && (
        <div className="transferownership-wrapper">
         {type2 && <div className="owner-addr">Please try with owner address</div>}
          <p id="transfer-title">{title}</p>
          <p className="transfer-owner-title">Recent Owner’s Address</p>
          <p className="wallet-addr-div">
            {prevOwner}
          </p>
          <p className="transfer-owner-title">
            Enter New owner's wallet address
          </p>
          <div className="ownership-input">
            <input style={{border: type2 && '1px solid red'}} disabled  placeholder="please enter address" value={address} onChange={handlerAddressChange} />
          </div>
          {prevOwner !== account && <div className="owner-error-div"> <AiOutlineWarning/> 
          <p>You are not an owner</p>
          </div>}
          <div className="owner-error-div"> <AiOutlineWarning/> 
          <p>Contact Blockchain Dev for assistance in ownership transfer</p>
          </div>
          <div className="ownership-button">
          {/* onClick={transferHandler} */}
            <Button disabled={address === null || address=== '' || prevOwner !== account ? true:false} type="primary" >
              {title}
            </Button>
          </div>
        </div>
      )}
      {/* {type2 && (
        <div className="transferownership-wrapper">
          <div className="owner-addr">Please try with owner address</div>
          <p id="transfer-title">{title}</p>
          <p className="transfer-owner-title">Recent Owner’s Address</p>
          <p className="wallet-addr-div">
            {prevOwner}
          </p>
          <p className="transfer-owner-title">
            Enter New owner's wallet address
          </p>
          <div className="ownership-input-div">
            <input placeholder="0x9a7A3c4D11806f27ce6b8904327e31BBd86f7266" />
          </div>
          <div className="ownership-button">
            <Button type="primary" onClick={TransferRealOwner}>
              {title}
            </Button>
          </div>
        </div>
      )} */}
      {/* {type3 && (
        <div className="transferownership-wrapper">
          <p id="transfer-title">{title}</p>
          <p className="transfer-owner-title">Enter owner's wallet address</p>
          <div className="ownership-input">
            <input onChange={inputHandler}  />
          </div>
          <div className="ownership-button">
            <Button type="primary" onClick={TransferSuccessfull}>
              {title}
            </Button>
          </div>
        </div>
      )} */}
      {/* {type5 && <div className="container" ref={Waitingcontainer}></div>} */}
      {modalType !== 'settings' && type4 && (
        <div className="transferownership-wrapper">
          <div className="container" ref={Successcontainer}></div>
          <p id="transfer-title">Ownership Transfered Successfully </p>
        </div>
      )}

{modalType === 'settings' && type4 && (
        <div className="transferownership-wrapper">
          <div className="container" ref={Successcontainer}></div>
          <p id="transfer-title">Admin Updated Successfully </p>
        </div>
      )}
    </div>
  );
};

export default TransferOwnerShipModal;
