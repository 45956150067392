import React, { useState } from "react";
import "../SidebarAdmin/AdminContent/AdminContent.css";
import { Button, Modal, Tabs } from "antd";
import PaymentList from "./PaymentList";
import AddPaymentMethod from "../../modal/addpaymentmethod";

const PaymentMethods = () => {
  const [isOpen, setIsOpen] = useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setSuccess(false)
    setIsOpen(false);
  };

  const [success, setSuccess] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)

  return (

    <>
      <div style={{ display: "flex", justifyContent: "flex-end", margin: "2rem 0" }}>
        <Button onClick={showModal} type="primary" style={{ padding: "10px 20px", height: "fit-content", maxWidth: "fit-content" }}>Add Payment Method</Button>
      </div>
      <div>
        <PaymentList isUpdated={isUpdated} />
      </div>

      <Modal
        className="add-liquidity-modal"
        open={isOpen}
        onCancel={handleCancel}
      >
        <AddPaymentMethod
          handleCancel={handleCancel}
          setIsUpdated={setIsUpdated}
          success={success}
          setSuccess={setSuccess}
          isUpdated={isUpdated}
        />
      </Modal>
    </>
  );
};

export default PaymentMethods;
