import React from "react";
import "./SelectionComponent.css";
import { Input, DatePicker, Slider } from "antd";
import dayjs from "dayjs";
import RangeSelector from "../../../../rangeSelector/RangeSelector";
import moment from "moment";
const SelectionComponent = ({setFilterValue,setIsFilter,setSelectedRange, setFilterDate}) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "DDMMMYY";
  const onChange = (value) => {
    console.log("onChange: ", value);
    setSelectedRange(value)
  };
  
 
  const changeHandler =(e)=>{
    setIsFilter(true)
    setFilterValue(e.target.value)
    
  };

  const rangePickerHandler = (date, dateString) => {
    const dateOne = moment(dateString[0]); 
    const dateTwo = moment(dateString[1]);
    if(date === null){
      setFilterDate([]);
    }else{
    setFilterDate([dateOne, dateTwo]);
    }
  };
  return (
    <div className="selected_divi_comp" style={{marginTop:"10px"}}>
      <Input  className="selection-input" onChange={changeHandler} placeholder="Search by type" />
      <RangePicker
        className="range-picker-comp"
        format={dateFormat}
        onChange={rangePickerHandler}
        // onOk={console.log("ok")}
      />
        <Slider
        
         className="slider-comp-antd"
         tooltip={{
          open: true,
        }}
        min={0}
        max={10000000}
    range={{
      draggableTrack: true,
    }}
    onChange={onChange}
    // defaultValue={[20, 50]}
  />
    </div>
  );
};

export default SelectionComponent;
