import React, { useEffect, useState } from "react";
import "./DashboardCard.css";
import LCTlogo from "../../../../../Assets/withdraw/locked-balance-logo.svg";
import LockedLogo from "../../../../../Assets/lock.svg";
import LCTLocked from "../../../../../Assets/admin/lctlock.svg";
import { useWeb3React } from "@web3-react/core";
import { Skeleton,Space } from 'antd';
import { exchangeContractAddress, exchangeStakeBalance, newBalance, tokenBalance, userStakeBalance } from "../../../../../util/contractIntractions";
import { priceConversion } from "../../../../../util";
const DashboardCard = ({
  width,
  title,
  value,
  usdBal,
  type,
  additionalText,
}) => {
  const { library, chainId, account, activate, deactivate, active } =
  useWeb3React();

  const [lctBal,setLctBal]=useState(0);
  const [stakedBal, setStakedBalance] = useState(0);
  const [USDLCT, setUSDLCTBalance] = useState(0);

  useEffect(() => {
    const fetchBalance = async  () => {
      setLctBal(await tokenBalance(exchangeContractAddress , library))
      setStakedBalance(await exchangeStakeBalance(library))
      setUSDLCTBalance(localStorage.getItem("lctusd"));
    }
    if(account){
      fetchBalance();
    }
  },[account]);

  return (
    <div style={{ width: width }} className="dashboard_wrapper">
      <p id="total-tokens">
        Total <span id="token-span">{title} </span> {additionalText}
        Tokens
      </p>
      <div className="dashboard_content">
        <div className="icon-value-div">
          {type ? <img src={LCTLocked} /> : <img src={LCTlogo} />}
          <div>
            {type && (
              <p
              id="locked-balance-header">
                Locked Balance
              </p>
            )}
            <p id="locked-balance" style={{display:"flex",alignItems:"center"}}>
              {type && (
                <img
                style={{marginTop:"0"}}
                 id='locked-img'
                  src={LockedLogo}
                />
              )}
              <span>
              {type === "locked" ? stakedBal : (parseFloat(lctBal) - parseFloat(stakedBal)).toFixed(4)}
              </span>
            </p>
          </div>
        </div>
        <p id="usd-value" style={{justifyContent:"flex-end"}}>
          USD Value:$ { account ? type === "locked" ?  parseFloat(stakedBal * USDLCT).toFixed(4) : (parseFloat(USDLCT*lctBal) - parseFloat(USDLCT*stakedBal)).toFixed(4): 
      <Skeleton   active paragraph={{ rows: 0}}/>
           
          }
        </p>
      </div>
    </div> 
  );
};

export default DashboardCard;
