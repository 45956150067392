import React, { useEffect, useState } from "react";
import SelectionComponent from "../../SidebarAdmin/AdminContent/SelectionComponent/SelectionComponent";
import "../ViewAll/ViewAll.css";
import stakeImg from "../../../../Assets/withdraw/stake-icon.svg";
import SwapImg from "../../../../Assets/withdraw/swap-exch.svg";
import FailedLogo from "../../../../Assets/failederror.svg";
import SuccessLogo from "../../../../Assets/successlogo.svg";
import { Table } from "antd";
import useGet from "../../../../Apis/LoginApi/TransactionApi/TransactionApi";
import { TRANSACTION_LINK } from "../../../../Utils/Apis";
import moment from "moment";
import { exchangeStakeHistory } from "../../../../util/contractIntractions";
import { useWeb3React } from "@web3-react/core";

const ContractData = ({tabIndex}) => {
  console.log(tabIndex);
  console.log('contract');
  const [isFilter, setIsFilter] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [trnxData, setTrnxData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedRange, setSelectedRange] = useState([]);
  const tokenData = JSON.parse(localStorage.getItem("user"));
  const [dateRange, setFilterDate] = useState([]);
  const filters = [selectedRange , dateRange , filterValue]
  const anyFilterApplied = filters.filter(flt => flt.length > 0).length > 0
  const { account,library,chainId, deactivate } =
  useWeb3React();

  const token = tokenData?.token;
  const data = [];
  const apiData = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      responsive: ["md"],
      render: (text) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {text === "stake" ? (
            <div
              style={{
                background: "rgba(156, 106, 253, 0.3)",
                borderRadius: "50%",
                marginRight: "10px",
                width: "40px",
                height: "40px",
                padding: "10px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={stakeImg}
                alt=" Stake"
              />
            </div>
          ) : (
            <div
              style={{
                background: "#04E2AF",
                borderRadius: "50%",
                marginRight: "10px",
                width: "40px",
                height: "40px",
                padding: "10px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={SwapImg}
                alt=" Swap"
              />
            </div>
          )}

          <span className="table-text">{text}</span>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date  ",
      responsive: ["md"],
      //   sorter: {
      //     compare: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      //   },
    },
    {
      title: "Asset",
      dataIndex: "asset",
      key: "asset",
      responsive: ["md"],
      render: (text) => {
        return <> {text} </>;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      responsive: ["md"],
    },
    // {
    //   title: "Address",
    //   dataIndex: "txn",
    //   key: "hash",
    //   responsive: ["md"],
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      responsive: ["md"],
      render: (text) => (
        <div className="table-btn">
          <span className={text === "success" ? "success_msg" : "failed_msg"}>
            {" "}
            {text === "failed" && (
              <img src={FailedLogo} alt="icon" style={{ marginRight: "5px" }} />
            )}
            {text === "success" && (
              <img
                src={SuccessLogo}
                alt="icon"
                style={{ marginRight: "5px" }}
              />
            )}
            {text ? text : <p className="empty_data">--</p>}
          </span>
        </div>
      ),
    },
  ];

  const releaseDateOfStake = (since, timeStaked) => {
    let date = new Date(since * 1000);
    const rleaseDate = moment(date).add(timeStaked, "M").format("DD-MM-YYYY");
    return rleaseDate;
  };
  const { getdata, response, error } = useGet(TRANSACTION_LINK, apiData);

  // const stakeData = trnxData.filter((item) => item.type.includes("stake"));
  const stakeData = trnxData

  stakeData.map((val, index) => {
    let date = new Date(val.since * 1000);

    return data.push({
      // type: val?.type,
      // date: moment(JSON.parse(val?.timestamp)).format("YYYY-DD-MM"),
      // asset: "LCT",
      // amount: Number(val?.amount).toFixed(2),
      // txn: `${val?.trxn_id.slice(0, 4)}--${val?.trxn_id.slice(
      //   val?.trxn_id.length - 4,
      //   val?.trxn_id.length
      // )}`,
      // status: val.status,


      since: +val.since,
      // date: moment(JSON.parse(val?.since)).format("YYYY-DD-MM"),
      date: val.timeStaked === "2" ? moment(date).format("YYYY-MM-DD") : releaseDateOfStake(val.since, val.timeStaked),
      // date: moment(date).format("YYYY-MM-DD"),
      type: "Stake",
      asset: "assest",
      timeStaked: val.timeStaked,
      amount: ((val?.amount * Math.pow(10, -18))).toFixed(2),
      status: val.stakeStatus ===  '1' ? "success":'failed',
      key: index,
      asset: "LCT",

    });
  });


  useEffect(() => {
    // getdata();
    (async() => {
      const resp = await exchangeStakeHistory(library, account);
      console.log(resp);
      let data = []
      for (let i = 0; i < resp.length; i++) {
        if(resp[i].address_stakes.length > 0){
          resp[i].address_stakes.forEach(element => {
            console.log(element);
            data.push(element)
          }); 
        }
      }
      console.log(data);
      setTrnxData(data)
    })()

  }, [account]);
  // useEffect(() => {
  //   if (response) setTrnxData(response?.data?.data);

  //   if (error) console.log(error);
  // }, [response, error]);
   useEffect(() => {
    if(filterValue){
      setFilterData(data.filter((item) => item.type.includes(filterValue)));
    }
  }, [filterValue]);
   useEffect(() => {
    if(selectedRange.length > 0){
      if(filterData.length > 0) {
        setFilterData(filterData.filter(items => {
          return items.amount >= selectedRange[0] && items.amount <= selectedRange[1]
        }))
        }else{
          setFilterData(data.filter(items => {
            return items.amount >= selectedRange[0] && items.amount <= selectedRange[1]
          }))
        }
    }
  }, [selectedRange])
  return (
    <div>
      <SelectionComponent
      setFilterDate={setFilterDate}
        setSelectedRange={setSelectedRange}
        setIsFilter={setIsFilter}
        setFilterValue={setFilterValue}
      />
      <Table
        columns={columns}
        dataSource={!anyFilterApplied ? data : filterData}
        pagination
        className="admin-antd-table"
      />
    </div>
  );
};

export default ContractData;
