import './App.css';
import Admin from './components/Admin/Admin';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from './components/Admin/Dashboard/Dashboard';
import Liquidity from './components/Admin/Dashboard/Liquidity/Liquidity';
import Login from './components/common/Login/Login';
import Withdraw from './components/Admin/Dashboard/Withdraw/Withdraw';
import Settings from './components/Admin/Dashboard/Settings/Settings';
import TransferOwnership from './components/Admin/Dashboard/TransferOwnership/TransferOwnership';
import { useEffect, useState } from 'react';
import { priceConversion } from './util';
import Protected from './Utils/Protected/Protected';
import { useCookies } from 'react-cookie';
import { RequireAuth } from 'react-auth-kit';
import PaymentMethods from './components/Admin/PaymenMethods/paymentmethods';

function App() {
 const [Cookies]= useCookies();

  useEffect(() => {
    const lctpriceFetch = async() => {
      localStorage.setItem("lctusd", await priceConversion("local-traders","usd"));
    }
    lctpriceFetch();
  }, [])
  useEffect (()=>{
    if(!Cookies.userToken){
      localStorage.removeItem("user");
      localStorage.removeItem("isAuth");
      localStorage.removeItem("password");
      console.log("no cookies")
    }
   },[Cookies.userToken]);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Login/>} />
          <Route path="/admin" element={  <RequireAuth loginPath="/"> <Admin/>  </RequireAuth>}>
                <Route index element={<Dashboard />} />
                {/* <Route path="liquidity" element={<Liquidity />} /> */}
                <Route path="withdraw" element={<Dashboard/>}/>
                <Route path="transferownership" element={<Dashboard/>}/>
                <Route path="payment-methods" element={<PaymentMethods/>}/>
                <Route path="settings" element={<Settings/>}/>
          </Route>
      </Routes>
      </Router>
    </div>
  );
}

export default App;


