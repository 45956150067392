import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
import { AuthProvider } from "react-auth-kit";

const root = ReactDOM.createRoot(document.getElementById('root'));
const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};
root.render(
  <>
  <AuthProvider
  authType={"cookie"}
  authName={"_auth"}
  cookieDomain={window.location.hostname}
  cookieSecure={false}>
    <Web3ReactProvider getLibrary={getLibrary}>
    <App />
    </Web3ReactProvider>
    </AuthProvider>
  </>
);


