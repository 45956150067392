import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, notification } from 'antd';
import Logo from "../../../../../Assets/logo1.svg";
import {MdErrorOutline} from 'react-icons/md';
import LoginApi from '../../../../../Apis/LoginApi/LoginApi';
import './FormComponent.css';
import { useAuthUser } from 'react-auth-kit';
import close from "../../../../../Assets/swap/close.svg"
import { fetchAdminAddress, setAdminAddress } from '../../../../../util/contractIntractions';
import { useWeb3React } from '@web3-react/core';
import TransferOwnerShipModal from '../../../../modal/TransferOwnerShipModal';
import { useNavigate } from 'react-router-dom';
const onFinish = (values) => {
  console.log('Success:', values);
};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};
const { TextArea } = Input;



const FormComponent = () => {
  const { account,library,chainId, deactivate } =
  useWeb3React();
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

const [isModalOpen, setIsModalOpen] = useState(false);
const [isModalOpenAdmin, setIsModalOpenAdmin] = useState(false);
const navigate = useNavigate();
const [prevOwner, setPrevOwner] = useState(null);
const [ownerAccount, setOwnerAccount] = useState(null);
const [isLiquidity, setIsLiquidity] = useState(false);

const [isEmailDisabled,setIsEmailDisabled]=useState(true);
const [oldPassWord,setOldPassword]=useState();
const [newPassword,setNewPasword]=useState();
const [confirmPassword,setConfirmPassword]=useState();
const [isError,setIsError]=useState(false);
const [isOk,setIsOk]=useState(false);
const [passError,setPassError]=useState(false);
const [adminAdd, setAdminAdd] = useState("");
const user = useAuthUser()().user;
const [adminAddress, setAdminAddress] = useState(null);
const emailHandler =()=>{
setIsEmailDisabled(false)
};
const passwordHandler=()=>{
  setIsModalOpen(true);
};
const handleOk = () => {
  setIsModalOpen(false);
  
};
const handleCancel = () => {
  setIsModalOpen(false);
  
};
const showModal = () => {
  setIsModalOpenAdmin(true);
   setIsLiquidity(false);
  setIsPaymentSuccess(false);
  setIsWaiting(false);
 };
const handleOkAdmin = () => {
  setOwnerAccount(null);
  setIsModalOpenAdmin(false);
  navigate('/admin/settings') 
  
};
const handleCancelAdmin = () => {
  setIsModalOpenAdmin(false);
  setIsLiquidity(false);
  setOwnerAccount(null);
  navigate('/admin/settings')

};
const oldPassWordHandler =(e)=>{
  setOldPassword(e.target.value)
};

const newPasswordHandler =(e)=>{
  setNewPasword(e.target.value)
};
const confirmPasswordHandler=(e)=>{
  setConfirmPassword(e.target.value)
};
const apiData =" https://lreubhjr4f.execute-api.us-east-1.amazonaws.com/admin/change-password";
const userData ={
  email: user?.email,
  newPassword: newPassword,
  oldPassword: oldPassWord
};
const {postdata,response,error} =LoginApi(apiData,userData);

useEffect(()=> {
  const fetchAdminAdd = async () => {
    const data = await fetchAdminAddress(library);
    console.log(data);
    setAdminAddress(data);
  }
  if(library){
    fetchAdminAdd();
  }
},[library, account])

useEffect(()=>{
    setIsError(false);
    setPassError(false);
    setIsOk(false);
},[isModalOpen]);
useEffect(()=>{
  if(response){
    setIsError(false);
    setPassError(false);
    setIsOk(true)
    console.log(response.data)
  }
  if(error){
    setPassError(true)
    console.log(error)
  }
},[response,error])

const modalPasswordHandler =()=>{
  if(newPassword !== confirmPassword){
    setIsError(true)
    return;
  }
  postdata();
  
};

const setAdmin = async () => {
  if(account){
    try {
      await setAdminAddress(account, library, adminAdd)
      notification.success({placement: "bottomRight", message: "Admin set Successfully"});
    } catch (error) {
      notification.error({placement:"bottomRight", message: "Somthing Went Wrong"});
      console.log(error)
    }
  }
}
console.log("adminAdd", adminAddress)
return (
  <>
  <Form
    name="basic"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    style={{ maxWidth: 600 }}
    initialValues={{ remember: true }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
    className='form-div-antd'
  >
    <div className='username-wrapper' >
        <Form.Item className='fname-wrapper'>
        <Form.Item label='FirstName'  id='label' />
    <Form.Item
      name="firstname"
      initialValue={user?.firstname}
      
      rules={[{ required: true, message: 'Please enter firstname!' }]}
    >
      <Input id='input' disabled  className='input-wrapper-div'/>
    </Form.Item>
    <Form.Item label='Email' />
    <Form.Item
      name="email"
      initialValue={user?.email}
      rules={[{ required: true, message: 'Please enter email!' }]}
    >
      <Input id='input'disabled={isEmailDisabled}   type='email' />
    </Form.Item>
    <Form.Item className='btn-div'> 
        <Button onClick={emailHandler} >Change Email Address</Button>
    </Form.Item>
    </Form.Item>


    <Form.Item className='lname-wrapper'>
        <Form.Item label='LastName'  id='label' />
    <Form.Item
      name="lastname"
      rules={[{ required: true, message: 'Please enter lastname!' }]}
      initialValue={user?.lastname}
      
    >
      <Input id='input' disabled   className='input-wrapper-div'/>
    </Form.Item>
    <Form.Item label='Password' />
    
    <Form.Item
      name="password"
      rules={[{ required: true, message: 'Please enter password!' }]}
      // initialValue={userPass}

    >
      <Input  id='input' disabled type='password' />
    </Form.Item>
    <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <img src={close} className='close-divii' onClick={handleCancel}/>
       <Form>
       <Form.Item label='Old Password' />
    
    <Form.Item
      name="Old Password"
      rules={[{ required: true, message: 'Please enter password!' }]}
    >
      <Input.Password value={oldPassWord} id='input1'  type='password' onChange={oldPassWordHandler} />
    </Form.Item>
    <Form.Item label='New  Password' />
    
    <Form.Item
      name="New Password"
      rules={[{ required: true, message: 'Please enter password!' }]}
    >
      <Input.Password value={newPassword} id='input1'  type='password' onChange={newPasswordHandler} />
    </Form.Item>
    <Form.Item label='Confirm New Password' />
    
    <Form.Item
      name="Confirm New Password"
      rules={[{ required: true, message: 'Please enter password!' }]}
    >
      <Input.Password value={confirmPassword}   id='input1'  type='password' onChange={confirmPasswordHandler} />
    </Form.Item>
    {isError && (
                <div className="warning-text warning-border">
                  <MdErrorOutline style={{ marginRight: "5px" }} />
                  New Password and Confirm New Password Didn't Match.
                </div>
              )}
              {passError && (
                <div className="warning-text warning-border">
                  <MdErrorOutline style={{ marginRight: "5px" }} />
                   Old Password Didn't Match.
                </div>
              )}
              {isOk && (
                <div className='success-border success-text'>
                  <MdErrorOutline  style={{ marginRight: "5px" }} />
                   Password Succesfully Changed.
                </div>
              )}
    <Form.Item className='btn-div-one'> 
        <Button disabled={oldPassWord ===  undefined || newPassword === undefined || confirmPassword === undefined ? true : false}  onClick={modalPasswordHandler} >Change Password</Button>
    </Form.Item>
    
              
       </Form>
      </Modal>
    <Form.Item className='btn-div'> 
        <Button  id='popup-btn'  onClick={passwordHandler} >Change Password</Button>
    </Form.Item>
    </Form.Item>


    <Form.Item className='lname-wrapper'>
        <Form.Item>
        <img src={Logo}/>
        </Form.Item>
        <Form.Item className='btn-div'> 
        <Button >Change Picture</Button>
    </Form.Item>
    </Form.Item>


    
    </div>
      {/* <Form.Item>
        <Form.Item label='Details' id='label' />
        <TextArea className='text-area-ant' rows={4}/>
      </Form.Item>
      <Form.Item className='update-btn-div'>
        <Button type='primary'>Update</Button>
      </Form.Item> */}
  </Form>

  <Form
     className='form-div-antd'
  >

<Modal
        className="add-liquidity-modal"
        open={isModalOpenAdmin}
        onOk={handleOkAdmin}
        onCancel={handleCancelAdmin}
      >
        <TransferOwnerShipModal
          prevOwner={prevOwner}
          type1={isModalOpenAdmin}
          type2={isLiquidity}
          type4={isPaymentSuccess}
          type5={isWaiting}
          adminAddress={adminAddress}
          modalType={'settings'}
          title={"Set Admin"}
          handleCancel={handleCancelAdmin}
        />
      </Modal>
    
    {/* <Form.Item> 
        <Button  id='popup-btn'  onClick={showModal} >Change Admin Price</Button>
    </Form.Item> */}
    {/* <Form.Item id='popup-btn'  className='update-btn-div'>
        <Button  onClick={showModal}  type='primary'>Change Admin Price</Button>
      </Form.Item> */}
  {/* <Form.Item>
       
        <Form.Item label='Set Price Admin' id='label' />
        
        <Input  id='set_price' onChange={(e) => setAdminAdd(e.target.value)} name='adminAddress'  type='text' placeholder={`Current Owner ${adminAddress && adminAddress}`} />

      </Form.Item>
      <Form.Item className='update-btn-div'>
        <Button  onClick={setAdmin} type='primary'>submit</Button>
      </Form.Item> */}
  </Form>
  </>
)};

export default FormComponent;