import React,{useEffect, useState} from 'react';
import SelectionComponent from '../../SidebarAdmin/AdminContent/SelectionComponent/SelectionComponent';
import '../ViewAll/ViewAll.css';
import stakeImg from "../../../../Assets/withdraw/stake-icon.svg";
import SwapImg from "../../../../Assets/withdraw/swap-exch.svg";
import moment from "moment";
import FailedLogo from "../../../../Assets/failederror.svg";
import SuccessLogo from "../../../../Assets/successlogo.svg";
import { Table } from 'antd';
import useGet from '../../../../Apis/LoginApi/TransactionApi/TransactionApi';
import { TRANSACTION_LINK } from '../../../../Utils/Apis';
import WithdrawPic from "../../../../Assets/with.svg";

const Withdrawal = () => {
  const [isFilter,setIsFilter]=useState(false);
  const [filterValue,setFilterValue]=useState('');
  const [selectedRange, setSelectedRange] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [tableData,setTableData]=useState([]);
  const [dateRange, setFilterDate] = useState([]);

  const tokenData = JSON.parse(localStorage.getItem('user'));
  const token = tokenData?.token;
  let swapData=[];
  let sdata =[];
  console.log(token);
  const apiData ={
    headers:{
      Authorization:`Bearer ${token}`
    }
  }

  const filters = [selectedRange , dateRange , filterValue]

  const anyFilterApplied = filters.filter(flt => flt.length > 0).length > 0

  const {getdata,response,error}=useGet(TRANSACTION_LINK,apiData);
    const columns = [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          responsive: ["md"],
          render: (text) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width : "150px"
              }}
            >
              {text === "stake" ? (
                <div
                  style={{
                    background: "rgba(156, 106, 253, 0.3)",
                    borderRadius: "50%",
                    marginRight: "10px",
                    width: "40px",
                    height: "40px",
                    padding: "10px",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={stakeImg}
                    alt=" Stake"
                  />
                </div>
              ) : (
                <div
                  style={{
                    background: "#04E2AF",
                    borderRadius: "50%",
                    marginRight: "10px",
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={WithdrawPic}
                    alt=" Swap"
                  />
                </div>
              )}
    
              <span className="table-text">{"Withdraw"}</span>
            </div>
          ),
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date  ",
          responsive: ["md"],
        //   sorter: {
        //     compare: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        //   },
        },
        {
          title: "Asset",
          dataIndex: "asset",
          key: "asset",
          responsive: ["md"],
          render: (text) => {
            return <div style={{
                width : "50px",
            }}> {text} </div>;
          },
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          responsive: ["md"],
        },
        {
          title: "Address",
          dataIndex: "txn",
          key: "hash",
          responsive: ["md"],
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          responsive: ["md"],
          render: (text) => (
            <div className="table-btn">
              <span className={text === "success" ? "success_msg" : "failed_msg"}>
                {" "}
               
                { text==="failed" &&  <img
                  src={FailedLogo}
                  alt="icon"
                  style={{ marginRight: "5px" }}
                /> }
                {  text ==="success" &&  <img
                  src={SuccessLogo}
                  alt="icon"
                  style={{ marginRight: "5px" }}
                /> }
                {text ? text : <p className="empty_data">--</p>}
              </span>
            </div>
          ),
        },
      ];
     
  //  const filterData=data.filter((item)=>item.type.includes(filterValue) );
  swapData = tableData.filter((item)=>item.type.includes('withdrawal'))
   swapData.map((val)=>{
    return sdata.push({
      type:val?.type,
      date:moment(Number(val?.timestamp)).format('YYYY-DD-MM'),
      asset:'LCT',
      amount:val?.amount,
      txn:`${val?.trxn_id.slice(0,4)}--${val?.trxn_id.slice(val?.trxn_id.length-4,val?.trxn_id.length)}`,
      status:val?.status,
    })
   })

   useEffect(()=>{
    getdata();
   },[]);
   useEffect(()=>{
    if(response) setTableData(response?.data?.data)
    if(error) console.log(error)
   },[response,error]);

   useEffect(() => {
    if(filterValue){
      setFilterData(sdata.filter((item) => item.type.includes(filterValue)));
    }
  }, [filterValue]);


  useEffect(() => {
    if(selectedRange.length > 0){
      if(filterData.length > 0) {
        setFilterData(filterData.filter(items => {
          return items.amount >= selectedRange[0] && items.amount <= selectedRange[1]
        }))
        }else{
          setFilterData(sdata.filter(items => {
            return items.amount >= selectedRange[0] && items.amount <= selectedRange[1]
          }))
        }
    }
  }, [selectedRange])

  useEffect(() => {
    if(dateRange.length > 0){
      const date1 = moment(moment(dateRange[0]).format("YYYY-DD-MM"),"YYYY-DD-MM") 
          const date2 = moment(moment(dateRange[1]).format("YYYY-DD-MM"),"YYYY-DD-MM") 
          console.log(date1, date2)
      if(filterData.length > 0) {
  
        setFilterData(filterData.filter(items => {
          return moment(items.date , "YYYY-DD-MM").isAfter(date1) && moment(items.date , "YYYY-DD-MM").isBefore(date2)
        }))
        }else{
          console.log(sdata.filter(items => {
          return  moment(items.date , "YYYY-DD-MM").isAfter(date1) && moment(items.date , "YYYY-DD-MM").isBefore(date2) 
          }))
          setFilterData(sdata.filter(items => {
          return  moment(items.date , "YYYY-DD-MM").isAfter(date1) && moment(items.date , "YYYY-DD-MM").isBefore(date2) 
          }))
        }
    }
  }, [dateRange])
  return (

    <div>
        <SelectionComponent 
        setFilterDate={setFilterDate}
        setSelectedRange={setSelectedRange}
        setIsFilter={setIsFilter} setFilterValue={setFilterValue}/>
        <Table
        scroll={{y:250}}
          columns={columns}
          dataSource={!anyFilterApplied ? sdata : filterData}
          pagination
                 className='admin-antd-table'
                />
    </div>
  )
}

export default Withdrawal;