import React, { useEffect, useState } from "react";
import SelectionComponent from "../../SidebarAdmin/AdminContent/SelectionComponent/SelectionComponent";
import "./ViewAll.css";
import stakeImg from "../../../../Assets/withdraw/stake-icon.svg";
import SwapImg from "../../../../Assets/withdraw/swap-exch.svg";
import WithdrawImg from "../../../../Assets/with.svg";
import moment from "moment";
import FailedLogo from "../../../../Assets/failederror.svg";
import SuccessLogo from "../../../../Assets/successlogo.svg";
import { Table,Button} from "antd";
import TransactionApi from "../../../../Apis/LoginApi/TransactionApi/TransactionApi";
import useGet from "../../../../Apis/LoginApi/TransactionApi/TransactionApi";
import { TRANSACTION_LINK, bscScanUrl } from "../../../../Utils/Apis";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

const ViewAll = () => {

  const [isFilter, setIsFilter] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [selectedRange, setSelectedRange] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const location =useLocation().pathname;
  const tokenData = JSON.parse(localStorage.getItem("user"));
  const [dateRange, setFilterDate] = useState([]);
  const [Cookies] = useCookies();
  const token = Cookies.userToken;
  console.log(token)

  const filters = [selectedRange , dateRange , filterValue]

  const anyFilterApplied = filters.filter(flt => flt.length > 0).length > 0
  

  const { getdata, loading, response, error } = useGet(
    TRANSACTION_LINK,
  );
  console.log(response)
useEffect(() => {
  console.log(filters)
console.log(anyFilterApplied)
},[filters])

// console.log("check response",response)
// console.log(moment(1682529380154).format('YY-MM-DD'))

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      responsive: ["md"],
      render: (text) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {text === "stake" ? 
            <div
              style={{
                background: "rgba(156, 106, 253, 0.3)",
                borderRadius: "50%",
                marginRight: "10px",
                width: "40px",
                height: "40px",
                padding: "10px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={stakeImg}
                alt=" Stake"
              />
            </div>
          :  text === "swap" ?
            <div
              style={{
                background: "#04E2AF",
                borderRadius: "50%",
                marginRight: "10px",
                width: "40px",
                height: "40px",
                padding: "10px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={SwapImg}
                alt=" Swap"
              />
            </div>
          : 
          <div
          style={{
            background: "#04E2AF",
            borderRadius: "50%",
            marginRight: "10px",
            width: "40px",
            height: "40px",
            padding: "10px",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            src={WithdrawImg}
            alt=" Swap"
          />
        </div>
        }

          <span className="table-text">{text}</span>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date  ",
      responsive: ["md"],
      sorter: {
        compare: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      },
    },
    {
      title: "Asset",
      dataIndex: "asset",
      key: "asset",
      responsive: ["md"],
      render: (text) => {
        return <> {text} </>;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      responsive: ["md"],
    },
    {
      title: "Txn",
      dataIndex: "txn",
      key: "hash",
      render:(text,data) => {
        console.log(text,data);
        return (
          <>
          {data.status !== 'success' ? (
            <>
              {text}
            </>
          ) : (
      <a href={data.hash} rel="noreferrer" target="_blank">
                        {text?.slice(0, 4)}--{text?.slice(
                    text?.length - 4,
                    text?.length
                  )}
                  </a>
                )}
                
          </>
         
        )
      },
      responsive: ["md"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      responsive: ["md"],
      render: (text) => (
        <div className="table-btn">
          <span className={text === "success" ? "success_msg" : "failed_msg"}>
            {" "}
            {text === "failed" && (
              <img src={FailedLogo} alt="icon" style={{ marginRight: "5px" }} />
            )}
            {text === "success" && (
              <img
                src={SuccessLogo}
                alt="icon"
                style={{ marginRight: "5px" }}
              />
            )}
            {text ? text : <p className="empty_data">--</p>}
          </span>
        </div>
      ),
    },
  ];

  let sdata = [];
   
  tableData?.map((item) => {
    return sdata.push({
      type: item.type,
      date: moment(JSON.parse(item.timestamp)).format("YYYY-DD-MM"),
      asset: item.asset ? item.asset : "LCT",
      amount: parseFloat(item.amount),
      hash: `${bscScanUrl}${item.symbol.txnId}`,
      txn: item.symbol.txnId ? `${item?.symbol?.txnId?.slice(0, 4)}--${item?.symbol?.txnId?.slice(
        item?.symbol?.txnId?.length - 4,
        item?.symbol?.txnId?.length
      )}`: `${item?.trxn_id?.slice(0, 4)}--${item?.trxn_id?.slice(
        item?.trxn_id?.length - 4,
        item?.trxn_id?.length
      )}`,
      status: item.status,
    });
  });

  useEffect(() => {
    if(filterValue){
      setFilterData(sdata.filter((item) => item.type.includes(filterValue)));
    }
  }, [filterValue]);


  useEffect(() => {
    if(selectedRange.length > 0){
      if(filterData.length > 0) {

        setFilterData(filterData.filter(items => {
          return items.amount >= selectedRange[0] && items.amount <= selectedRange[1]
        }))
        }else{
          setFilterData(sdata.filter(items => {
            return items.amount >= selectedRange[0] && items.amount <= selectedRange[1]
          }))
        }
    }
  }, [selectedRange]);
      
  // date filter needs to work here
  useEffect(() => {
    if(dateRange.length > 0){
      const date1 = moment(moment(dateRange[0]).format("YYYY-DD-MM"),"YYYY-DD-MM") 
          const date2 = moment(moment(dateRange[1]).format("YYYY-DD-MM"),"YYYY-DD-MM") 
          console.log(date1, date2)
      if(filterData.length > 0) {
  
        setFilterData(filterData.filter(items => {
          return moment(items.date , "YYYY-DD-MM").isAfter(date1) && moment(items.date , "YYYY-DD-MM").isBefore(date2)
        }))
        }else{
          console.log(sdata.filter(items => {
          return  moment(items.date , "YYYY-DD-MM").isAfter(date1) && moment(items.date , "YYYY-DD-MM").isBefore(date2) 
          }))
          setFilterData(sdata.filter(items => {
          return  moment(items.date , "YYYY-DD-MM").isAfter(date1) && moment(items.date , "YYYY-DD-MM").isBefore(date2) 
          }))
        }
    }
  }, [dateRange])



  useEffect(() => {
    getdata();
  }, []);
  useEffect(() => {
    if (response) setTableData(response?.data?.data);
    if (error) console.log(error);
  }, [response, error]);

  console.log(sdata)
  return (
    <div className="view-all-wrapper">
      <SelectionComponent
        setSelectedRange={setSelectedRange}
        setIsFilter={setIsFilter}
        setFilterValue={setFilterValue}
        setFilterDate={setFilterDate}
      />
      <Table
        columns={columns}
        dataSource={!anyFilterApplied ? sdata : filterData}

        pagination
        className="admin-antd-table"
      />
      {location ==='/admin/liquidity' &&
      <div className="liquidity-button-div">
                <Button type="primary">
                  Remove Liquidity
                </Button>
                <Button type="primary">
                  Add Liquidity
                </Button>
              </div>
}
    </div>
  );
};

export default ViewAll;
