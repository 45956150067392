import React from "react";
import "./AdminSidebar.css";
import Logo from "../../../Assets/admin/logo.svg";
import LogoutImg from "../../../Assets/admin/logout.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { HiOutlineBookOpen } from "react-icons/hi";
import {IoWalletOutline} from "react-icons/io5";
import {IoSettingsOutline} from "react-icons/io5";
import {BsWindowPlus} from 'react-icons/bs';
import {BsPeopleFill} from 'react-icons/bs';
import { useWeb3React } from "@web3-react/core";
import { useCookies } from "react-cookie";
import { useSignOut } from "react-auth-kit";

const AdminSidebar = ({setIsLoading,setIsModalOpen}) => {
  const location =useLocation().pathname;
  const [Cookies,removeCookie] = useCookies();
  const { account, deactivate } = useWeb3React();
  const Navigate = useNavigate();
  const signOut = useSignOut();
  const sidebarData = [
    {
      symbol: <MdDashboard size={25} className='react-icons'/>,
      title: "Dashboard",
      addr:"/admin",
    },
    // {
    //   symbol: <HiOutlineBookOpen size={25} className='react-icons'/>,
    //   title: "Liquidity",
    //   addr:"/admin/liquidity",
    // },
    // {
    //   symbol: <IoWalletOutline size={25} className='react-icons'/>,
    //   title: "Withdraws",
    //   addr:"/admin/withdraw",
    // },
    {
      symbol: <BsPeopleFill size={25} className='react-icons'/>,
      title: "Transfer Ownership",
      addr:"/admin/transferownership",
    },
    {
      symbol: <BsWindowPlus size={25} className='react-icons'/>,
      title: "Payment Methods",
      addr:"/admin/payment-methods",
    },
    {
      symbol: <IoSettingsOutline size={25} className='react-icons'/>,
      title: "Settings",
      addr:"/admin/settings"
    },
  ];
  const logoutHandler =()=>{
    setIsModalOpen(false);
    setIsLoading(true)
      signOut();
      deactivate();
      Navigate("/");
  };

  return (
    <div className="adminsidebar-wrapper">
      <div className="sidebar-logo">
      <img src={Logo} />
      </div>
      <div className="divider_div"></div>

      <div className="link-div">
          {sidebarData.map((item,index)=>{
            return <NavLink className={() => (location ===item.addr ? "isactive" : "link-wrapper")} key={index} to={item.addr}>{item.symbol} {item.title}</NavLink>
          })}
      </div>
      <div onClick={logoutHandler} className="logout-div">
        <NavLink className="link-wrapper"  >
          <img  src={LogoutImg} />
          Logout
        </NavLink>
      </div>
    </div>
  );
};

export default AdminSidebar;
