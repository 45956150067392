import { Button } from "antd";
import React, { useEffect } from "react";
import "./AdminHeader.css";
import Logo from "../../../../Assets/admin/icon.svg";
import { useWeb3React } from "@web3-react/core";
import walletIcon from "../../../../Assets/walletIcon.svg";
import { useAuthHeader, useAuthUser, useIsAuthenticated } from "react-auth-kit";

const AdminHeader = ({ showModal }) => {
  const { account, deactivate } = useWeb3React();
  const userData = useAuthUser()().user;
  // console.log(account)
  const disconnectWallet = async () => {
    deactivate();
  };
  useEffect(()=>{
    if(!account){
        showModal();
    }
  },[account])
  return (
    <div className="admin-header">
      <div className="header_btn_div">
        <div className="dashboard-title">
          <p id="dashboard-title">Dashboard</p>
          <p id="welcome-div">
            <span id="welcome-span">Welcome Back </span>{" "}
             {userData && userData?.firstname} !
          </p>
        </div>
        <div className="dashboard_button">
          <Button
            type="primary"
            onClick={account ? disconnectWallet : showModal}
          >
            {" "}
            {account ? (
              <div className="wallet-div-wrapper">
                {" "}
                <img src={walletIcon} />{" "}
                {`${account.slice(0, 3)}...${account.slice(
                  account.length - 4,
                  account.length
                )}`}
              </div>
            ) : (
              "Connect Wallet"
            )}
          </Button>
        </div>
      </div>
      <div className="profile_div">
        <div className="profile-name">
          <p id="title-text">{userData?.firstname}</p>
          <p id="email-text">{userData?.email}</p>
        </div>
        <img src={Logo} />
      </div>
    </div>
  );
};

export default AdminHeader;
