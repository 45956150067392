import React,{useEffect, useState,useRef} from 'react';
import { Outlet } from 'react-router-dom';
import './Admin.css';
import AdminHeader from './SidebarAdmin/AdminHeader/AdminHeader';
import AdminSidebar from './SidebarAdmin/AdminSidebar';
import {Modal } from 'antd';
import ConnectWalletModal from '../modal/ConnectWalletModal';
import { useWeb3React } from '@web3-react/core';
import lottie from "lottie-web";
import Dashboard from './Dashboard/Dashboard';

const Admin = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading,setIsLoading]=useState(false);
  const loadingContainer =useRef(null);
  const { account, deactivate } =
    useWeb3React();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
 
  useEffect(()=>{
    if(account){
      setIsModalOpen(false)
    }
  },[account]);
  useEffect(() => {
    lottie.loadAnimation({
      container: loadingContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true, 
      animationData: require("../../Lottie/circle.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, [isLoading]);
  return (<>
    <Modal className='modal-wrapper' open={isModalOpen} onOk={handleOk}>
       <ConnectWalletModal
        handleCancel={handleCancel}
       />
      </Modal>
    <div className='admin-main-wrapper'>
    {isLoading && <div  className="loader-contain" ref={loadingContainer}></div>}
        <AdminSidebar  setIsModalOpen={setIsModalOpen} setIsLoading={setIsLoading}/>
        <div className='admin-content-wrapper'>
            <AdminHeader showModal={showModal}/>
            {/* <Dashboard/> */}
            <Outlet/>
        </div>
    </div>
    </>
  )
}

export default Admin;