import React, { useEffect } from "react";
import MetaLogo from "../../Assets/swap/meta.svg";
import TrustLogo from "../../Assets/swap/trust.svg";
import WalletLogo from "../../Assets/swap/connect.svg";
import CloseIcon from "../../Assets/swap/close.svg";
import { useWeb3React } from "@web3-react/core";
import { supportedNetwork } from "../../util";
import { switchNetwork } from "../../WalletIntegrations/switchNetwork";
import { connectors } from "../../WalletIntegrations/connectors";
import './ModalComp.css';

const ConnectWalletModal = ({ handleCancel = { handleCancel } }) => {
  const { library, chainId, account, activate, deactivate, active, connector } =
  useWeb3React();

useEffect(() => {
  if (chainId === supportedNetwork) {
    switchNetwork(library, supportedNetwork);
  }
}, [chainId, library, active]);

  const walletData = [
    {
      image: MetaLogo,
      title: "Metamask",
    },
    {
      image: TrustLogo,
      title: "trust Wallet",
    },
    {
      image: WalletLogo,
      title: "Wallet Connect",
    },
  ];

  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };

  const metamaskConnect = () => {
    activate(connectors.injected);
    setProvider("injected");
  };

  return (
    <div >
      <div className="connect-wallet-div">
        {/* <img className="close-icon" onClick={handleCancel} src={CloseIcon} /> */}
        <p className="wallet-header">Connect Your Wallet</p>
        <p className="wallet-paragraph">
          Please select a wallet from below to connect for Stake
        </p>
        <div className="wallet-handler">
          {walletData.map((item, index) => {
            return (
              <div className="wallet-div" onClick={ item.title==='Metamask' ? metamaskConnect :null} key={index}>
                <img src={item.image} />
                <p>{item.title}</p>
                {item.title !== "Metamask" && (
                  <div className="modal-div-layout">
                    <p>Coming Soon</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ConnectWalletModal;
