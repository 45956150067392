import React, { useEffect, useState } from "react";
import "../Dashboard/ViewAll/ViewAll.css";
import { Table } from "antd";
import { GET_PAYMENT_METHODS, PAYMENT_METHODS_URL, TRANSACTION_LINK } from "../../../Utils/Apis";
import moment from "moment";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";

const PaymentList = ({
  isUpdated
}) => {

  const user = useAuthUser()().user;

  const [paymentData,setPaymentData] = useState([])
  const [loading,setLoading] = useState(false)


  useEffect(() => {
    const getdata = async () => {
      setLoading(true)
      let headers =  {
        headers: {
            Authorization: `Bearer ${user.token}`,
        }    
      };
      await axios
        .get(`${GET_PAYMENT_METHODS}`,headers)
        .then((res) => {
          setPaymentData(res.data.data)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };
    getdata();
  },[isUpdated])

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      responsive: ["md"],
       

    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      responsive: ["md"],
       

    },
    {
      title: "Create At",
      dataIndex: "createdAt",
      key: "createdAt  ",
      responsive: ["md"],
      render : (props)=>{
        const custom_date = moment(props.createdAt)
          .local()
          .format("DD-MM-YYYY")
        return <span>{custom_date}</span>
    }
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt  ",
      responsive: ["md"],
      render : (props)=>{
        const custom_date = moment(props.updatedAt)
          .local()
          .format("DD-MM-YYYY")
        return <span>{custom_date}</span>
    }
    },
  ];

  console.log(paymentData)


  return (
    <div style={{width:"100%"}}>
       
        <Table
        columns={columns}
        dataSource={paymentData}
        loading={loading}
        pagination
        className="payment-antd-table"
      />
      
      
    </div>
  );
};

export default PaymentList;
