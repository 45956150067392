import axios from "axios";


export const supportedNetwork = 56;
export const _liveRate = 57770;

export const toHex = (num) => {
    const val = Number(num);
    return "0x" + val.toString(16);
  };
  

export const priceConversion = async (from, to) => {
  try{
      const response = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${from}&vs_currencies=${to}`);
      return response.data[from][to];
  }
  catch(err) {
    return new Error(err);
  }
}