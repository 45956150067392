import React, { useEffect, useState } from "react";
import "../SidebarAdmin/AdminContent/AdminContent.css";
import GraphIcon from "../../../Assets/graphs.svg";
import { Modal, Tabs } from "antd";

import DashboardCard from "../SidebarAdmin/AdminContent/DashboardCard/DashboardCard";
import SelectionComponent from "../SidebarAdmin/AdminContent/SelectionComponent/SelectionComponent";
import GraphCard from "../SidebarAdmin/AdminContent/GraphCard/GraphCard";
import AdminBalanceCard from "../SidebarAdmin/BalanceCard/BalanceCard";
import ViewAll from "./ViewAll/ViewAll";
import StakeData from "./stakeData/StakeData";
import SwapData from "./swapData/SwapData";
import { connectors } from "../../../WalletIntegrations/connectors";
import { useWeb3React } from "@web3-react/core";
import { exchangeContract, tokenBalance } from "../../../util/contractIntractions";
import { priceConversion } from "../../../util";
import {  useLocation, useNavigate } from "react-router-dom";
import SwitchNetworkPopUP from "../../../WalletIntegrations/SwitchNetworkPopUp";
import ConnectMetamaskPopUP from "../../../WalletIntegrations/ConnectMetmaskPopUp";
import GraphComponent from "../../../Utils/GraphComponent/GraphComponent";
import Withdrawal from "./Withdrawal/Withdrawal";
import { useCookies } from "react-cookie";
import WithdrawModal from "../../modal/WithdrawModal";
import TransferOwnerShipModal from "../../modal/TransferOwnerShipModal";
import ContractData from "./ContractData/ContractData";

const Dashboard = () => {
  const navigate  = useNavigate();
  const [lctBal,setLctBal]=useState(0);
  const [usdLctBalance, setUSDLCTBalance] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTransferOpen, setIsTransferOpen] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);
  const [isLiquidity, setIsLiquidity] = useState(false);
  const [isTransferLiquidity, setIsTransferLiquidity] = useState(false);
  const [ownerAccount, setOwnerAccount] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isRealOwner, setIsRealOwner] = useState(false);
  const [prevOwner, setPrevOwner] = useState(null);
  const [address, setAddress] = useState(null);
  const [ownerError,setOwnerError] =useState(false);
  const [rejected , setRejected] = useState(false)

  const [cookies]=useCookies();
  const { library, chainId, account, activate, deactivate, active } =
  useWeb3React();
  // const tokenData = JSON.parse(localStorage.getItem('user'));
  const currentPath = useLocation().pathname;

    useEffect(() => {
      const fetchBalance = async  () => {
        setLctBal(await tokenBalance(account , library));
        setUSDLCTBalance(await priceConversion("local-traders","usd"));
      }
      if(library){
        fetchBalance();
      }
    }, [account]); 
    const [tabIndex,setTabIndex] = useState('1')

  const items = [
    {
      key: "1",
      label: `View All`,
      children: <ViewAll  tabIndex={setTabIndex}/>,
    },
    {
      key: "2",
      label: `Stake`,
      children: <StakeData tabIndex={setTabIndex} />,
    },
    // {
    //   key: "3",
    //   label: `Swap`,
    //   children: <SwapData tabIndex={setTabIndex} />,
    // },
    {
      key: "4",
      label: `Withdraw`,
      children: <Withdrawal tabIndex={setTabIndex} />,
    },
    {
      key: "5",
      label: `Contract Data`,
      children: <ContractData tabIndex={setTabIndex}/>,
    },
  ];
  const liquidityItems =[
    {
      key: "1",
      label: `Liquidity`,
      children: <ViewAll />,
    },
  ];
  const onChange = (key) => {
    setTabIndex(key)
  };
  const showModal =async() => {
    setIsModalOpen(true);
    setIsOpen(true);
    setIsLiquidity(false);
    setIsWithdraw(false);
    setRejected(false)
  };

  const handleOk = () => {
    setIsModalOpen(false);
    navigate('/admin')
  };
  const handleCancel = () => {
    navigate("/admin");
    setIsModalOpen(false);
    navigate('/admin')
  };

  const liquidityHandler = () => {
    setIsLiquidity(true);
    setIsOpen(false);
  };
  const ErrorHandler = async() => {
    setIsOpen(false);
    setIsWithdraw(true);    
    if(account){
      const exchange = await exchangeContract(library);
      try {
        await exchange.methods.adminWithdraw(account).send({from: account}).on("transactionHash", (hash) => {
        setIsModalOpen(false)
        navigate('/admin')
          console.log(hash);
          navigate("/admin")
        })
      } catch (error) {
        setRejected(true)
        // setIsModalOpen(false)
        // navigate('/admin')
        console.log("error", error)
        navigate("/admin")

      }  
    }
    
  };
  const showTransferModal = () => {
    setOwnerError(false)
    setIsTransferModalOpen(true);
    setIsTransferOpen(true);
    setIsTransferLiquidity(false);
    setIsPaymentSuccess(false);
    setIsWaiting(false);
    setIsRealOwner(false);
  };

  const handleTransferOk = () => {
    setOwnerAccount(null);
    setIsTransferModalOpen(false);
    navigate('/admin')
  };
  const handleTransferCancel = () => {
    setOwnerAccount(null);
    setIsTransferModalOpen(false);
    navigate("/admin")
  };

  const TransferRealOwner = async () => {
    setIsTransferLiquidity(false);
    setIsTransferOpen(false);
    setIsRealOwner(true);
  };
  const TransferSuccessfull = async () => {
    setIsTransferLiquidity(false);
    setIsTransferOpen(false);
    setIsRealOwner(false);
    setIsWaiting(true);
  };
  const inputHandler = (e) => {
    console.log(e.target.value)
    setOwnerAccount(e.target.value);
  };
  const transferHandler = async() => {
    if (account) {
      try {
        const exchange = await exchangeContract(library);
        const owner = await exchange.methods.owner().call();
        // setPrevOwner(owner);
        if (owner === account) {
          await exchange.methods
            .transferOwnership(address)
            .send({ from: account })
            .on("transactionHash", (hash) => {
              setAddress('')
              // setIsOpen(false)
              // setIsPaymentSuccess(true);
              console.log(hash);
  
            });
        } else {
        // setIsLiquidity(true);
        setAddress('')
          alert("You are not owner");
        }
      } catch (error) {
        setAddress('')
        if(error.code === 4001){
          // setIsModalOpen(false)
        }
        // setIsLiquidity(true);
        console.log(error, "error");
      }
    }
  };

  useEffect(()=>{
 if(currentPath === '/admin/withdraw'){
  showModal();
 }
 if(currentPath === '/admin/transferownership'){
  showTransferModal();
 }
},[currentPath])

const setProvider = (type) => {
  window.localStorage.setItem("provider", type);
};

// useEffect(()=>{
//   activate(connectors.injected);
//   setProvider("injected");
// },[])
console.log(lctBal);
  return (
    
    <>
      <div className="content_wrapper">
        <div className="admin_card_div">
          <div className="dashboard_div">
            <DashboardCard
              width="47%"
              title="LCT"
              value={lctBal}
              usdBal={Number(usdLctBalance * lctBal).toFixed(6)}
            />
            <DashboardCard
              width="49%"
              title="LCT"
              value="20,000.00"
              usdBal="120.00770"
              type="locked"
              additionalText="Staked"
            />
          </div>
          <div className="admin-table-wrapper">
            <Tabs defaultActiveKey="1" items={ currentPath ==="/admin/liquidity" ? liquidityItems :items} onChange={onChange} />
          </div>
        </div>
        <div className="admin-card-balances">
          <div className="balances_div">
            <AdminBalanceCard />
          </div>
          <div className="graph-div-overview">
            <GraphCard />
            {/* <img id="graph-img" src={GraphIcon} /> */}
            <GraphComponent/>
          </div>
        </div>
      </div>

      <SwitchNetworkPopUP/>
      <ConnectMetamaskPopUP/>
      <Modal
        className="add-liquidity-modal"
        open={isModalOpen}
        onOk={rejected? handleOk:null}
        onCancel={rejected ? handleCancel:null}
      >
        <WithdrawModal
        rejected = {rejected}
          handleCancel={handleCancel}
          title="Withdraw"
          type1={isOpen}
          type3={isWithdraw}
          ErrorHandler={ErrorHandler}
        />
      </Modal>
      <Modal
        className="add-liquidity-modal"
        open={isTransferModalOpen}
        onOk={handleTransferOk}
        onCancel={handleTransferCancel}
      >
        <TransferOwnerShipModal
          prevOwner={prevOwner}
          type1={isTransferOpen}
          type2={isTransferLiquidity}
          type4={isPaymentSuccess}
          type5={isWaiting}
          title={"Transfer Ownership"}
          handleCancel={handleTransferCancel}
          ownerError={ownerError}
          setOwnerError={setOwnerError}
        />
      </Modal>
    </>
  );
};

export default Dashboard;
