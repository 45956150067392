import React, { useEffect, useState } from "react";
import "./BalanceCard.css";
import { AiFillPlusSquare } from "react-icons/ai";
import CardBalance from "./CardBalance.js/CardBalance";
import LCTimg from "../../../../Assets/withdraw/coin-icon.svg";
import BNBimg from "../../../../Assets/bnb.svg";
import {
  exchangeContractAddress,
  exchangeStakeBalance,
  tokenBalance,
  userBalance,
} from "../../../../util/contractIntractions";
import { useWeb3React } from "@web3-react/core";
import { priceConversion } from "../../../../util";
import { networks } from "../../../../util/networks";

const AdminBalanceCard = () => {
  const { library, chainId, account, activate, deactivate, active } =
    useWeb3React();
  const [lctBalance, setLctBalance] = useState(0);
  const [usdLctBalance, setUSDLCTBalance] = useState(0);
  const [userBalanceWallet, setUserBalance] = useState(0);
  const [userBalanceToUsD, setUserBalanceToUsd] = useState(0);
  const [networkData, setNetworkData] = useState(null);
  const [stakedBal, setStakedBalance] = useState(0);


  const BalanceHandler = () => {
    // alert("click");
  };

  useEffect(() => {
    const fetchBalance = async () => {
      setLctBalance(await tokenBalance(exchangeContractAddress, library));
      setUSDLCTBalance(localStorage.getItem("lctusd"));
      setStakedBalance(await exchangeStakeBalance(library))
      setUserBalance(await userBalance(exchangeContractAddress, library));
      setUserBalanceToUsd(
        await priceConversion(networks[chainId].token, "usd")
      );
    };
    if (library) {
      setNetworkData(networks[chainId]);
      fetchBalance();
    }
  }, [account, chainId]);
  console.log(lctBalance)
  return (
    <div className="admin-balance-card-wrapper">
      <div className="balnce-divi">
        <p>Balances</p>
       
      </div>
      <div className="admin-card-holder">
        <CardBalance
          title={"LCT"}
          img={LCTimg}
          lctToUsd={usdLctBalance}
          amount={lctBalance}
          stakedBal={stakedBal}
          type ={'staked'}
        />
        <div className="divider-wrapp"></div>
        {networkData && (
          <CardBalance
            title={networkData.currency}
            lctToUsd={userBalanceToUsD}
            img={networkData.icon}
            amount={userBalanceWallet}
          />
        )}
      </div>
    </div>
  );
};

export default AdminBalanceCard;
