import React from "react";
import "../../SidebarAdmin/AdminContent/AdminContent.css";
import GraphIcon from "../../../../Assets/graphs.svg";
import GraphCard from "../../SidebarAdmin/AdminContent/GraphCard/GraphCard";
import AdminBalanceCard from "../../SidebarAdmin/BalanceCard/BalanceCard";
import FormComponent from "./FormComponent/FormComponent";
import SwitchNetworkPopUP from "../../../../WalletIntegrations/SwitchNetworkPopUp";
import GraphComponent from "../../../../Utils/GraphComponent/GraphComponent";
const Settings = () => {
  return (
    <>
      <div className="content_wrapper">
        <div className="admin_setting_div">
          <p className="settings-header">Account Setting</p>
           <FormComponent/>
        </div>
        <div className="admin-card-balances">
          <div className="balances_div">
            <AdminBalanceCard />
          </div>
          <div className="graph-div-overview">
            <GraphCard />
            <GraphComponent/>
          </div>
        </div>
      </div>
      <SwitchNetworkPopUP/>
    </>
  );
};

export default Settings;
