import React from 'react';
import './CardBalance.css';
import LCTimg from '../../../../../Assets/withdraw/coin-icon.svg';

const CardBalance = ({title,img,amount, lctToUsd,stakedBal,type}) => {
  return (
    <div className='bal-card'>
         <div className='logo-and-baldiv'>
            <img  src={img} />
            <div style={{
                display:'flex',
                flexDirection:'column',
                width:'70%'
                
            }}>
                <p style={{fontFamily:'bold',fontSize:'13px'}}>{title}</p>
                {type === 'staked' ? (
                                <>    <p style={{fontFamily:'semibold',fontSize:'20px'}}>{ amount ? (parseFloat(amount)-parseFloat(stakedBal)).toFixed(4) : 0}</p>
                                    <p style={{color:'#1B1773',fontSize:"12px",fontFamily:'semibold'}}>USD Value:$ {Number(lctToUsd * ( parseFloat(amount)-parseFloat(stakedBal)).toFixed(4) ).toFixed(4)}</p></>


                ) : (
                  <>
                      <p style={{fontFamily:'semibold',fontSize:'20px'}}>{ amount ? amount : 0}</p>
                <p style={{color:'#1B1773',fontSize:"12px",fontFamily:'semibold'}}>USD Value:$ {Number(lctToUsd * (amount) ).toFixed(4)}</p>
                  </>
                  
                )}
            </div>
         </div>
         {/* <div className='percentage-div' >
            <p>+2.6%</p>
         </div> */}
    </div>
  )
}

export default CardBalance