import Lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react'

const UseLottie = ({ name,params, className }) => {
    const loadingContainer = useRef(null);
    useEffect(() => {
        Lottie.loadAnimation({
            container: loadingContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: require(`../../Lottie/${name}.json`),
        });
        return () => {
            Lottie.destroy();
        };
    }, params);
    return (
        <div className={className} ref={loadingContainer}></div>
    )
}

export default UseLottie