import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";
import CloseIcon from "../../Assets/swap/close.svg";
import BNBIcon from "../../Assets/bnb.svg";
import AlertIcon from "../../Assets/alert.svg";
import lottie from "lottie-web";
import "../Admin/SidebarAdmin/AdminContent/AdminContent.css";
import { useWeb3React } from "@web3-react/core";
import { networks } from "../../util/networks";
import {
  contractBalanceBnB,
  userBalance,
} from "../../util/contractIntractions";
import { priceConversion } from "../../util";

const WithdrawModal = ({
  handleCancel,
  title,
  type1,
  type2,
  type3,
  liquidityHandler,
  ErrorHandler,
  rejected
}) => {
  const container = useRef(null);
  const { library, chainId, account, activate, deactivate, active } =
    useWeb3React();
  const [networkData, setNetworkData] = useState(null);
  const [userBalanceWallet, setUserBalanceWallet] = useState(0);
  const [userBalanceWalletUsd, setUserBalanceWalletUsd] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState("fetching...");

  useEffect(() => {
    const fetchDataBalanceAndNetworkData = async () => {
      setNetworkData(networks[chainId]);
      setUserBalanceWallet(await userBalance(account, library));
      setUserBalanceWalletUsd(
        (await priceConversion(networks[chainId].token,"usd")));
    };

    if (account) {
      fetchDataBalanceAndNetworkData();
    }
  }, [chainId, account, library]);
  console.log(networkData,userBalanceWallet,userBalanceWalletUsd)

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: rejected ? require("../../Lottie/tnxerror.json") : require("../../Lottie/waiting.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, [type3 , rejected]);

  const getValue = async () => {
    setWithdrawAmount(await contractBalanceBnB(library));
  };

  useEffect(() => {
    getValue();
  }, []);
  useEffect(() => {
console.log(rejected)
  },[rejected])
  return (
    <>
      {networkData && (
        <>
          <div className="close-div">
           {!type3 && <img onClick={handleCancel} src={CloseIcon} />}
          </div>
          <div className="modal-content-wrapper">
            <p className="balance-header">Your Balance</p>
            <div className="lct-main-wrapper-div" style={{ width: "70%" }}>
              <div className="logo-div-wrapper-icon">
                <img src={networkData.icon} />
              </div>
              <div>
                <p className="lct-val-text">
                  <span style={{ color: "#F3BA2F" }}>
                    {networkData.currency}
                  </span>{" "}
                  {withdrawAmount}
                </p>
                <p className="usd-lct-text">
                  USD Value:${(userBalanceWalletUsd * withdrawAmount).toFixed(4) }
                </p>
              </div>
            </div>
            <div className="divider-modal"></div>
            {type1 && (
              <>
                <div className="add-liquidity">
                  <p id="liquidity-title">{title}</p>
                  {/* <p id="liquidity-amount-title">
                    Enter{" "}
                    <span style={{ color: "#F3BA2F" }}>
                      {networkData.currency}
                    </span>{" "}
                    Amount
                  </p> */}
                </div>
                {/* <div className="liquidity-button-div-wrapper-container">
                  <input
                    className="max-input-container"
                    placeholder={withdrawAmount}
                    disabled
                  />
                  <p className="network-data-currency">
                    {networkData.currency}
                  </p>
                </div> */}
                <div className="btn-main-wrappr">
                  <Button type="primary" onClick={ErrorHandler}>
                    {title}
                  </Button>
                </div>
              </>
            )}
            {type2 && (
              <>
                <div className="add-liquidity">
                  <p id="liquidity-title">{title}</p>
                  <p id="liquidity-amount-title">
                    Enter <span style={{ color: "#F3BA2F" }}>BNB</span> Amount
                  </p>
                </div>
                <div className="liquidity-button-div-wrapper">
                  <div className="liquidity-btn-div">+</div>
                  <div className="max-input-div">
                    <input placeholder="24,125.235" />
                    <p
                      style={{
                        color: "#515050",
                        fontFamily: "regular",
                        cursor: "pointer",
                      }}
                    >
                      Max
                    </p>
                  </div>
                  <div className="liquidity-btn-div">-</div>
                </div>
                <div className="btn-main-wrappr">
                  <Button type="primary" onClick={ErrorHandler}>
                    Withdraw
                  </Button>
                </div>
                <div className="alert-error">
                  <img src={AlertIcon} />
                  <p>only owner will withdraw</p>
                </div>
              </>
            )}
            {type3 && (
              <>
                {" "}
                <p id="liquidity-title">Withdraws in progress</p>
                <div className="container" ref={container}></div>{" "}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default WithdrawModal;
