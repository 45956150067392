import React, { useEffect, useState } from "react";
import SelectionComponent from "../../SidebarAdmin/AdminContent/SelectionComponent/SelectionComponent";
import "../ViewAll/ViewAll.css";
import stakeImg from "../../../../Assets/withdraw/stake-icon.svg";
import SwapImg from "../../../../Assets/withdraw/swap-exch.svg";
import FailedLogo from "../../../../Assets/failederror.svg";
import SuccessLogo from "../../../../Assets/successlogo.svg";
import { Table } from "antd";
import useGet from "../../../../Apis/LoginApi/TransactionApi/TransactionApi";
import { TRANSACTION_LINK } from "../../../../Utils/Apis";
import moment from "moment";

const StakeData = () => {
  const [isFilter, setIsFilter] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [trnxData, setTrnxData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedRange, setSelectedRange] = useState([]);
  const tokenData = JSON.parse(localStorage.getItem("user"));
  const [dateRange, setFilterDate] = useState([]);
  const filters = [selectedRange , dateRange , filterValue]
  const anyFilterApplied = filters.filter(flt => flt.length > 0).length > 0

  const token = tokenData?.token;
  const data = [];
  const apiData = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      responsive: ["md"],
      render: (text) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {text === "stake" ? (
            <div
              style={{
                background: "rgba(156, 106, 253, 0.3)",
                borderRadius: "50%",
                marginRight: "10px",
                width: "40px",
                height: "40px",
                padding: "10px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={stakeImg}
                alt=" Stake"
              />
            </div>
          ) : (
            <div
              style={{
                background: "#04E2AF",
                borderRadius: "50%",
                marginRight: "10px",
                width: "40px",
                height: "40px",
                padding: "10px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={SwapImg}
                alt=" Swap"
              />
            </div>
          )}

          <span className="table-text">{text}</span>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date  ",
      responsive: ["md"],
      //   sorter: {
      //     compare: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      //   },
    },
    {
      title: "Asset",
      dataIndex: "asset",
      key: "asset",
      responsive: ["md"],
      render: (text) => {
        return <> {text} </>;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      responsive: ["md"],
    },
    {
      title: "Address",
      dataIndex: "txn",
      key: "hash",
      responsive: ["md"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      responsive: ["md"],
      render: (text) => (
        <div className="table-btn">
          <span className={text === "success" ? "success_msg" : "failed_msg"}>
            {" "}
            {text === "failed" && (
              <img src={FailedLogo} alt="icon" style={{ marginRight: "5px" }} />
            )}
            {text === "success" && (
              <img
                src={SuccessLogo}
                alt="icon"
                style={{ marginRight: "5px" }}
              />
            )}
            {text ? text : <p className="empty_data">--</p>}
          </span>
        </div>
      ),
    },
  ];

  const { getdata, response, error } = useGet(TRANSACTION_LINK, apiData);
  const stakeData = trnxData.filter((item) => item.type.includes("stake"));
  stakeData.map((val, index) => {
    return data.push({
      type: val?.type,
      date: moment(JSON.parse(val?.timestamp)).format("YYYY-DD-MM"),
      asset: "LCT",
      amount: Number(val?.amount).toFixed(2),
      txn: `${val?.trxn_id.slice(0, 4)}--${val?.trxn_id.slice(
        val?.trxn_id.length - 4,
        val?.trxn_id.length
      )}`,
      status: val.status,
    });
  });


  useEffect(() => {
    getdata();
  }, []);
  useEffect(() => {
    if (response) setTrnxData(response?.data?.data);

    if (error) console.log(error);
  }, [response, error]);
   useEffect(() => {
    if(filterValue){
      setFilterData(data.filter((item) => item.type.includes(filterValue)));
    }
  }, [filterValue]);
   useEffect(() => {
    if(selectedRange.length > 0){
      if(filterData.length > 0) {
        setFilterData(filterData.filter(items => {
          return items.amount >= selectedRange[0] && items.amount <= selectedRange[1]
        }))
        }else{
          setFilterData(data.filter(items => {
            return items.amount >= selectedRange[0] && items.amount <= selectedRange[1]
          }))
        }
    }
  }, [selectedRange])
  return (
    <div>
      <SelectionComponent
      setFilterDate={setFilterDate}
        setSelectedRange={setSelectedRange}
        setIsFilter={setIsFilter}
        setFilterValue={setFilterValue}
      />
      <Table
        columns={columns}
        dataSource={!anyFilterApplied ? data : filterData}
        pagination
        className="admin-antd-table"
      />
    </div>
  );
};

export default StakeData;
