import React,{useEffect,useState} from 'react';
import moment from 'moment';
import './GraphComponent.css';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import useGet from "../../Apis/LoginApi/TransactionApi/TransactionApi";
import { MARKET_PLACE } from '../../Utils/Apis';

const GraphComponent = () => {
     const [justStakeOpen,setJustStakeOpen]=useState(false)
      const [data, setData] = useState(null);
      const {getdata, response, error} = useGet(MARKET_PLACE)
    
      useEffect(() => {
        getdata()
      }, []);
      useEffect(() => {
        if(response){
          setData(response.data)
        }
        if(error){
          console.log(error)
        }
      },[response,error])
      const options = {
        title: {
          text: "",
        },
        chart: {
          height: justStakeOpen ? 500 : 270,
          marginTop: 20,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          type: "Text",
          lineColor: 'transparent',
          title: {
            enabled: false,
          },
          labels: {
            enabled:false
          },
        visible:false,

         
        },
        yAxis: {
            labels: {
                enabled: false
            },
            gridLineColor: 'transparent',
            title: {
                text: null
            }

        },
        
    
        navigator: {
          enabled: false,
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, Highcharts.getOptions().colors[9]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[9])
                    .setOpacity(0)
                    .get("rgba"),
                ],
              ],
            },
          },
        },
    
        tooltip: {
          formatter: function () {
              return this.points.reduce(function (s, point) {
                  return s + '<br/>' + "USD" + ': ' +
                     parseFloat(point.y).toFixed(4);
              }, '<b> Date: ' + moment(this.x).format("DD/MMM/YYYY") + '</b>');
          },
          shared: true
        },
    
        series: [
          {
            color: "#04e2af",
            type: "area",
            name: "LCT",
            data: data?.marketData?.lctData.prices,
    
            // },
            // {
            //     name: 'BTC',
            //     data: data.marketData.btcData.stats,
            //     tooltip: {
            //       valueDecimals: 6
            //     }
          },
        ],
        credits: {
            enabled: false
        },
      };
    console.log(data)
  return (
    <div className='graph-wrapper'>
        <HighchartsReact
        highcharts={Highcharts}
        // constructorType={"stockChart"}
        options={options}
      />
    </div>
  )
}

export default GraphComponent;