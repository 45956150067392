import React, { useEffect, useState } from "react";
import "./Login.css";
import LCTLogo from "../../../Assets/Login/lctlogo.svg";
import LoginImg from "../../../Assets/Login/loginpic.svg";
import { Form, Input, Button, Checkbox } from "antd";
import { Link, useNavigate } from "react-router-dom";
import LoginApi from "../../../Apis/LoginApi/LoginApi";
import { USER_SERVICE_LINK } from "../../../Utils/Apis";
import { MdErrorOutline } from "react-icons/md";
import { useCookies } from "react-cookie";
import { useSignIn } from "react-auth-kit";
import UseLottie from "../../../Utils/UseLottie/index";

const Login = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState("optional");
  const [email, setEmail] = useState(localStorage.getItem("email") && localStorage.getItem("email") );
  const [password, setPassword] = useState(localStorage.getItem("password"));
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState(false);
  const [isAuth, setisAuth] = useState(false);
  const [remember, setRemember ] = useState(localStorage.getItem("remember"));
  const signIn = useSignIn();

  const loginData = {
    email: email,
    password: password,
  };
  const { postdata, loading, response, error } = LoginApi(
    USER_SERVICE_LINK,
    loginData
  );

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };
  const inputHander = (e, setfn, type ) => {
    const value = e.target.value;
    setfn(value)
    if(remember === 'true'){
      localStorage.setItem(type, value)
    }
  }

  useEffect(() => {
    if (response?.data?.data?.isAuth) {
      signIn({
        token: response.data.data.user.token,
        expiresIn: 3600,
        tokenType: "Bearer",
        authState: { user: response.data.data.user },
      });
      navigate("/admin");
    }
    if (error) {
      setErrorMessage(true);
    }
    
  }, [response, error, loading]);

  
  const loginHandler = () => {
    if(email === ''){
      setErrorEmailMessage(true);
    }
    if(password === ''){
      setErrorMessage(true)
    }
    if(remember === true){
      localStorage.setItem("email", email)
      localStorage.setItem("password", password)
    }
    postdata();
  };

  useEffect(() => {
    if(localStorage.getItem("logout")){
      // removeCookie('userToken');
     }else{
       localStorage.removeItem("logout")
     }
    setEmail(localStorage.getItem("email"))
    setisAuth(localStorage.getItem("isAuth"));
  }, []);


  const checkHandler = (e) => {
    const checked = e.target.checked;
    if(checked){
      setRemember(true)
      localStorage.setItem("remember", true)
    }else{
      localStorage.setItem("remember", false)
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
  }

  return (
      <div className="login-wrapper">
        <div className="login-banner-bg-wrapper">
          <div className="login-banner">
            <div className="mobile-logo-div">
              <img src={LCTLogo} />
            </div>
            <div className="mobile-login-footer">
              <p className="login-text">Login</p>
              <p className="welcome-text">
                Welcome Back{" "}
                <span className="welcome-text-span">Local Traders</span>
              </p>
              <div className="footer-mobile-links">
                <p>Privacy Policy</p>
                <p>Contact</p>
                <p>© 2023 </p>
              </div>
            </div>
            <div className="login-icon-container">
              <Link to="#">
                {" "}
                <img className="logo-div" src={LCTLogo} />
              </Link>
              <img className="login-img" src={LoginImg} />
              <div className="login-text-div">
                <p className="login-text">Login</p>
                <p className="welcome-text">
                  Welcome Back{" "}
                  <Link to="#" className="welcome-text-span">
                    Local Traders
                  </Link>
                </p>
              </div>
              <div className="privacy-policy-div">
                <Link to="#">Privacy Policy</Link>
                <Link to="#">Contact</Link>
                <p>© 2023 </p>
              </div>
            </div>
            <div className="divider-wrapper"></div>
            <div className="login-form-div">
              <p className="form-welcome-text">Welcome to Local Traders</p>
              <p className="from-sign-in-text">
                Sign in by entering information below
              </p>
              {errorMessage && (
                <div className="warning-text warning-border">
                  <MdErrorOutline style={{ marginRight: "5px" }} />
                  Please enter a correct email address and password.<br/> Note that
                  both fields may be case-sensitive
                </div>
              )}
              {
                loading && <UseLottie className={'loader-container'} name={'circle'}  params={[loading]} />
              }
              <Form
                form={form}
                layout="vertical"
                initialValues={{
                  requiredMarkValue: requiredMark,
                }}
                onValuesChange={onRequiredTypeChange}
                requiredMark={requiredMark}
                className='login-form-wrapper'
              >
                <Form.Item
                  label="Email"
                  required
                  name="Email"
                  initialValue={email}
                  className="email-label"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => inputHander(e , setEmail, 'email')}
                    placeholder="Enter your email"
                    id="form-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  required
                  name="Password"
                  initialValue={password}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                >
                  <Input.Password
                    className="password-input"
                    placeholder="********"
                    onChange={(e) => inputHander(e , setPassword, 'password')}
                  />
                </Form.Item>
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  wrapperCol={{
                    offset: 6,
                    span: 16,
                  }}
                >
                  <Checkbox defaultChecked={remember === "true" ? true : false} onChange={checkHandler} className="remember-pref-div">
                    Remember my preference
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    disabled={email === "" || password === "" ? true :false}
                    htmlType="submit"
                    type="primary"
                    onClick={loginHandler}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Login;
