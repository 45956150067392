import axios from "axios";
import { useState } from "react";
import { useAuthUser } from "react-auth-kit";

const useGet = (api, data={}) => {
  const [response, setresponse] = useState(null);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(null);
  const user = useAuthUser()().user;

  const getdata = async () => {
    setloading(true);
    let headers =  {
      headers: {
          Authorization: `Bearer ${user.token}`,
      }    
    };
    await axios
      .get(api,headers, data)
      .then((res) => {
        console.log(res.data)
        setresponse(res);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        seterror(err);
        setloading(false);
      });
  };

  return { getdata, loading, response, error };
};

export default useGet;
