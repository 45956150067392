export const networks = {
    5: {
        name: "Georli",
        currency: "ETH",
        token: "ethereum",
        icon: process.env.PUBLIC_URL + 'switchicons/ETH.svg',
        showname: "Ether"
      },
      1: {
        name: "Etheruem",
        currency: "ETH",
        token: "ethereum",
        icon: process.env.PUBLIC_URL + 'switchicons/ETH.svg',
        showname: "Ethers"
      },
      56: {
        name: "Binance Chain",
        currency: "BNB",
        token: "binancecoin",
        icon: process.env.PUBLIC_URL + 'switchicons/BNBldpi.svg',
        showname: "Binance"
      },
      11155111: {
        name: "Sepolia",
        currency: "ETH",
        token: "binancecoin",
        icon: process.env.PUBLIC_URL + 'switchicons/ETH.svg',
        showname: "Binance"
      },
      42161: {
        name: "Arbitrum One",
        currency: "ETH",
        token: "binancecoin",
        icon: process.env.PUBLIC_URL + 'switchicons/ETH.svg',
        showname: "Binance"
      },
      137: {
        name: "Polygon Mainnet",
        currency: "MATIC",
        token: "matic-network",
        icon: process.env.PUBLIC_URL + 'switchicons/polygon.svg',
        showname: "MATIC"
      },
      80001: {
        name: "Polygon Mumbai",
        currency: "MATIC",
        token: "matic-network",
        icon: process.env.PUBLIC_URL + 'switchicons/polygon.svg',
        showname: "MATIC"
      },
      3: {
        name: "Ropsten",
        currency: "ETH",
        token: "binancecoin",
        icon: process.env.PUBLIC_URL + 'switchicons/ETH.svg',
        showname: "Binance"
      },
      43114: {
        name: "Avalanche C-Chain",
        currency: "AVAX",
        token: "binancecoin",
        icon: process.env.PUBLIC_URL + 'switchicons/ETH.svg',
        showname: "Binance"
      },
      10: {
        name: "Optimism",
        currency: "ETH",
        token: "binancecoin",
        icon: process.env.PUBLIC_URL + 'switchicons/ETH.svg',
        showname: "Binance"
      },
      250: {
        name: "Fantom Opera",
        currency: "FTM",
        token: "binancecoin",
        icon: process.env.PUBLIC_URL + 'switchicons/ETH.svg',
        showname: "Binance"
      },
      25: {
        name: "Cronos Mainnet Beta",
        currency: "CRO",
        token: "binancecoin",
        icon: process.env.PUBLIC_URL + 'switchicons/ETH.svg',
        showname: "Binance"
      },
    };
    export const defaultNetwork = {
      name: "Network not Supported",
      currency: "N/A",
      token: "binancecoin",
      icon: "",
      showname: "Binance"
    };
    