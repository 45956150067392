import { toHex } from "../util";
import { networkParams } from "./networksParams";


export const switchNetwork = async (library, network) => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(network) }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [networkParams[toHex(network)]],
          });
        } catch (error) {
            throw new Error('Network not able to Switch');
        }
      }
    }
  };