import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";

import Alert from "../Assets/common/alert.svg";
import Eth from "../Assets/swapNew/eth.png";
import ArrowImg from "../Assets/common/arr.svg";
import MaticImg from "../Assets/swapNew/polygon.svg";
// import BNB from "../../../../Assets/swap/BNB.svg";
import { useWeb3React } from "@web3-react/core";
import { defaultNetwork, networks } from "../util/networks";
import { supportedNetwork } from "../util/contractIntractions";
import { switchNetwork } from "./switchNetwork";

const SwitchNetworkPopUP = () => {
    const { library, chainId, account, activate, deactivate, active } = useWeb3React();
  const [isOpen, setIsOpen] = useState(false);
  const [networkData, setNetworkData] = useState(null);
  useEffect(() =>{
    if(window.ethereum){
        if(account){
            if(chainId === supportedNetwork){
                setIsOpen(false);
            }
            if(chainId !== supportedNetwork){
                setIsOpen(true);
                setTimeout(() => {
                    switchNetwork(library, supportedNetwork);
                }, 300)
            }else {
                setIsOpen(false)
            }
        }
       
    }
    if(chainId){

      setNetworkData(
        networks[chainId] === undefined ? defaultNetwork : networks[chainId]
      );
    }
  }, [chainId, account])
  // console.log("network",networkData)
  return (
    <>
      <Modal
        title=""
        open={isOpen}
        onOk={() => {
          setIsOpen(false);
        }}
        onCancel={() => {
          setIsOpen(false);
        }}
        width={450}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <img style={{ width: "50px", height: "50px" }} src={Alert} alt="icon" />
          <p
            style={{
              margin: "20px auto",
              color: "#1B1773",
              fontFamily: "regular",
              fontSize: "15px",
            }}
          >
            Please change your Network from MetaMask
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "80%",
              margin: "auto",
            }}
          >
            <div>
              <img style={{ width: "50px", height: "50px" }} src={
                networkData === null ? Eth : networkData.icon
              } alt="icon" />
              <p style={{ color: "#1B1773", fontFamily: "semiBold", textAlign: "center" }}>
                {networkData === null ? "Ethereum" : networkData.name}
              </p>
            </div>
            <img style={{
              width: "70px",
              paddingBottom: "20px",
            }} src={ArrowImg} alt="icon" />
            <div>
              <img style={{ width: "50px", height: "50px" }} src={networks[supportedNetwork].icon} alt="icon" />
              <p style={{ color: "#1B1773", fontFamily: "semiBold", textAlign: "center" }}>{networks[supportedNetwork].name}</p>
            </div>
          </div>
          <div
            style={{ width: "100%", textAlign: "center", margin: "20px auto" }}
          >
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
              style={{ width: "60%", border: "none", fontFamily: "Regular" , height: "40px"}}
              type="primary"
            >
              Done
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default SwitchNetworkPopUP;
