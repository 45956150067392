import axios from "axios";
import { useState } from "react";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";

const LoginApi = (api, data) => {
  const [response, setresponse] = useState(null);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(null);
  const useUser = useAuthUser()();
  const token = useUser && useUser.token;
  const postdata = async () => {
    setloading(true);
    let headers =  {
      headers: {
          Authorization:  `Bearer ${token}`
      }    
    };
    await axios
      .post(api, data, headers)
      .then((res) => {
        setresponse(res);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        seterror(err);
        setloading(false);
      });
  };

  return { postdata, loading, response, error };
};

export default LoginApi;
