import React, { useEffect, useState } from "react";
import { Modal } from "antd";

import Alert from "../Assets/common/alert.svg";

const ConnectMetamaskPopUP = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() =>{
    if(!window.ethereum){
        setIsOpen(true)
    }else{
        setIsOpen(false)
    }
  }, [])

  return (
    <>
      <Modal
        title=""
        open={isOpen}
        onOk={() => {
          setIsOpen(false);
        }}
        onCancel={() => {
          setIsOpen(false);
        }}
        width={450}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <img style={{ width: "50px", height: "50px" }} src={Alert} />
          <p
            style={{
              margin: "20px auto",
              color: "#1B1773",
              fontFamily: "regular",
              fontSize: "15px",
            }}
          >
            Please install MetaMask
          </p>
            <a href="https://metamask.io/download/" target="_blank">Click here to install</a>
        </div>
      </Modal>
    </>
  );
};
export default ConnectMetamaskPopUP;
