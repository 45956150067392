import Web3 from "web3";
import { ethers } from "ethers";
import LTCExchange from "../abis/LTCExchange.json";
import TokenContract from "../abis/DaiToken.json";
import USDTContract from "../abis/USDTToken.json";
import priceContract from "../abis/LctPrice.json";

export const supportedNetwork = 56;
let _liveRate = 57770;

export const exchangeContractAddress = LTCExchange.networks[supportedNetwork].address;
export const tokenContractAddress = TokenContract.networks[supportedNetwork].address;


export const exchangeContract = (library) => {
  const web3 = new Web3(library.provider);
  const exchangeData = LTCExchange.networks[supportedNetwork];
  const exchange = new web3.eth.Contract(LTCExchange.abi, exchangeData.address);
  // console.log(exchange)
  return exchange;
};

export const tokenContract = async (library) => {
  const web3 = new Web3(library.provider);
  const tokenData = TokenContract.networks[supportedNetwork];
  const token = new web3.eth.Contract(TokenContract.abi, tokenData.address);
  return token;
};

export const setPriceContract = async (library) => {
  const web3 = new Web3(library.provider);
  const priceData = priceContract.networks[supportedNetwork];
  const price = new web3.eth.Contract(priceContract.abi, priceData.address);
  return price;
};


export const usdtContract = async () => {
  const web3 = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/"));
  const token = new web3.eth.Contract(USDTContract, "0x55d398326f99059ff775485246999027b3197955");
  return token;
}

export const tokenBalance = async (account, library) => {
  try {
    const token = await tokenContract(library);
    const balance = await token.methods.balanceOf(account).call();
    const fiatBalance = ethers.utils.formatUnits(balance);
    return Number(fiatBalance).toFixed(2);
  } catch (error) {
    throw new Error(error);
  }
};

export const userBalance = async (account, library) => {
  try {
    const web3 = new Web3(library.provider);
    let userBalance = web3.utils.fromWei(await web3.eth.getBalance(account));
    return Number(userBalance).toFixed(2);
  } catch (error) {
    throw new Error(error);
  }
};

export const swapTokens = async (account, library, swapAmount) => {
  const exchange = await exchangeContract(library);
  const web3 = new Web3(library.provider);

  setTimeout(() => {
    // now i am giving 5777 for 0.1 bnb
    exchange.methods
      .buyTokens(_liveRate)
      .send({
        value: web3.utils.toWei(swapAmount),
        from: account,
      })
      .on("transactionHash", (hash) => {
        console.log("Transaction Completed", hash);
        return hash;
      });
  }, 2000);
};

export const userStakeBalance = async (account, library) => {
  try {
    const exchange = await exchangeContract(library);
    const balance = await exchange.methods.stakingBalance(account).call();
    const fiatBalance = ethers.utils.formatUnits(balance);
    return Number(fiatBalance).toFixed(4);
  } catch (error) {
    throw new Error(error);
  }
};

export const exchangeStakeBalance = async (library) => {
  try {
    const exchange = await exchangeContract(library);
    const balance = await exchange.methods.exchangeStakedTokens().call();
    const fiatBalance = ethers.utils.formatUnits(balance);
    return Number(fiatBalance).toFixed(4);
  } catch (error) {
    throw new Error(error);
  }
};

export const exchangeStakeHistory = async (library) => {
  try {
    const exchange = await exchangeContract(library);
    const data = await exchange.methods.exchangeHistoryOfStake().call();
    return data
  } catch (error) {
    throw new Error(error);
  }
};

export const userStakeHistory = async (account, library) => {
  try {
    const exchange = await exchangeContract(library);
    const balance = await exchange.methods.userHistoryOfStake().call({
      from: account,
    });
    return balance;
  } catch (error) {
    throw new Error(error);
  }
};

export const usdtUserBalance = async (account) => {
  try {
    const token = await usdtContract();
    const balance = await token.methods.balanceOf(account).call();
    const fiatBalance = ethers.utils.formatUnits(balance);
    return Number(fiatBalance).toFixed(4);
  } catch (error) {
    throw new Error(error);
  }
};


export const listLiquidity = async(library, account) => {
  try {
    const exchange = exchangeContract(library);
    console.log(exchange.methods)
    const list = await exchange.methods.get_Investor(account).call();
    return list;
  } catch (error) {
    throw new Error(error);

  }
}

export const contractBalanceBnB = async(library) => {
  try {
    const exchange = exchangeContract(library);
    const balance = await exchange.methods.getBalance().call();
    const formattedBalance = ethers.utils.formatUnits(balance);
    return formattedBalance;
  } catch (error) {
    console.log(error)
  }
}



export const setAdminAddress = async (account,library, address) => {
  try {
    const priceContractFetch = await setPriceContract(library);
    console.log(priceContractFetch)
    await priceContractFetch.methods.handleSetLtcAdmin(address).send({
      from: account
    });
    return true;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAdminAddress = async (library) =>{
  try {
    const priceContractFetch = await setPriceContract(library);
    console.log(priceContractFetch)
    const adminAddress= await priceContractFetch.methods.ltcPriceAdmin().call();
    console.log(adminAddress)
    return adminAddress;
  } catch (error) {
    throw new Error(error);

  }
}