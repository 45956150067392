import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../Assets/swap/close.svg";
import { Button, Form, Input } from "antd";
import "../Admin/SidebarAdmin/AdminContent/AdminContent.css";
import axios from "axios";
import { Spin } from 'antd';
import Lottie from "lottie-react";
import animationData from "../../Lottie/txnsuccess.json";

import { ADD_PAYMENT_METHOD } from "../../Utils/Apis";
const AddPaymentMethod = ({
  handleCancel,
  setIsUpdated,
  isUpdated,
  setSuccess,
  success,
}) => {
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm();

  const paymentMethodHandler = async (data) => {

    try {
      const res = await axios.post(`${ADD_PAYMENT_METHOD}`, data)
      if (res) {
        setLoading(false)
        setIsUpdated(!isUpdated)
        form.resetFields()
        setSuccess(true)
      }
    } catch (error) {
      console.log(error)
      setLoading(false);

    }
  }


  const onFinish = (values) => {
    console.log('Success:', values);
    setLoading(true)
    paymentMethodHandler(values)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <div>
      <div className="close-div">
        <img onClick={handleCancel} src={CloseIcon} />
      </div>

      <div className="transferownership-wrapper">
        {!success ?
          <>
            <p id="transfer-title" style={{ fontSize: 25 }}>Add Payment Method</p>
            <Form
              form={form}
              name="basic"
              // labelCol={{ span: 8 }}
              // wrapperCol={{ span: 16 }}
              style={{ width: "100% !important" }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className='form-div-antd'
            >
              <Form.Item label='Name' />
              <Form.Item
                style={{ width: "100%!important" }}
                name="name"
                rules={[{ required: true, message: 'Field is mandatory' }]}
              >
                <Input />
              </Form.Item>


              {/* {isError && (
        <div className="warning-text warning-border">
          <MdErrorOutline style={{ marginRight: "5px" }} />
          New Password and Confirm New Password Didn't Match.
        </div>
      )} */}

              <Form.Item style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                <Button disabled={loading} style={{ padding: "10px 20px", height: "fit-content" }} type="primary" htmlType="submit" >
                  Add Method
                  {loading && (
                    <span style={{ marginLeft: "10px" }}>
                      <Spin size="small" />
                    </span>
                  )}
                </Button>
              </Form.Item>


            </Form>
          </>
          : (
            <div className="transferownership-wrapper">
              <p id="transfer-title" style={{ fontSize: 25, marginBottom: "1rem" }}>Added Successfully </p>
              <Lottie
                style={{ width: "55%", margin: "0 auto" }}
                loop={false}
                animationData={animationData}
              />
            </div>
          )}
        {/* <div className="owner-error-div"> <AiOutlineWarning />
          <p>You are not an owner</p>
        </div> */}
      </div>

    </div>
  );
};

export default AddPaymentMethod;
