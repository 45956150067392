import { Select } from 'antd';
import React from 'react';
import './GraphCard.css';

const GraphCard = () => {
    const handleChange = (value) => {
        console.log(`selected ${value}`);
      };
  return (
    <div className='admin-graph-wrapper'>
        <div className='admin-graph-header'>
        <div><p 
         className='market-overview'
        >Market Overview</p>
         {/* <p className='market-value'>7.2141,997.5(25%)</p> */}
        </div>
        {/* <div>
            <Select
              defaultValue="ALL"
              className='select-all-div'
              onChange={handleChange}
              options={[
                {
                  value: 'jack',
                  label: 'Jack',
                },
                {
                  value: 'lucy',
                  label: 'Lucy',
                },
                {
                  value: 'Yiminghe',
                  label: 'yiminghe',
                },
                {
                  value: 'disabled',
                  label: 'Disabled',
                  disabled: true,
                },
              ]}
            ></Select>
        </div> */}
        </div>
    </div>
  )
}

export default GraphCard;